* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f2f2f2;
}

.logos {
  overflow: hidden;
  padding: 30px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 100px; /* Adjust width for responsiveness */
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-flex;
}

.logos-slide img {
  height: 100px; /* Adjust the height for responsiveness */
  margin: 0 5px; /* Adjust the space between logos for responsiveness */
}

/* Media queries for different screen sizes */
@media (min-width: 768px) {
  .logos-slide img {
    height: 125px; /* Larger height for tablets and larger devices */
    margin: 0 8px; /* More space between logos for larger screens */
  }
}

@media (min-width: 1024px) {
  .logos-slide img {
    height: 150px; /* Largest height for desktop devices */
    margin: 0 10px; /* Default space between logos */
  }
}
